import React, { useState } from 'react';
import { Box, Stack, Typography, Divider, IconButton, Tooltip, Snackbar, Alert } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import Logo from "../../assets/Images/logo.ico";
import { Copy, ThumbsDown, ThumbsUp } from 'phosphor-react';
import { sendFeedback } from '../../utils/api';

const PromptMsg = ({ el }) => {
  return (
    <>
      <Box sx={{ py: 2.5, px: 1 }}>
        <Typography variant="h4">
          {el.message}
        </Typography>
      </Box>
    </>
  )
}

const AnswerMsg = ({ el }) => {

  console.log(el)
  
  const [feedbackStatus, setFeedbackStatus] = useState({
    copy: false,
    good_response: false,
    report: false
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleFeedback = async (type) => {
    try {
      await sendFeedback(type, el.message_id);
      
      // Show success snackbar with specific messages
      const messages = {
        copy: 'Text copied to clipboard!',
        good_response: 'Thank you for your positive feedback!',
        report: 'Thank you for reporting this response.'
      };
      
      setSnackbarMessage(messages[type]);
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error(`Failed to send ${type} feedback: `, err);
      
      // Show error snackbar
      setSnackbarMessage(`Failed to send feedback. Please try again.`);
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(el.message);
      console.log('Message copied to clipboard');
      await handleFeedback('copy', el.message_id);
    } catch (err) {
      console.error('Failed to copy message: ', err);
    }
  };

  const handleGoodResponse = async () => {
    try {
      await handleFeedback('good_response', el.message_id);

      // Update local state to reflect feedback
      setFeedbackStatus(prev => ({
        ...prev,
        ["good_response"]: true,
      }));    
      setFeedbackStatus(prev => ({
        ...prev,
        ["report"]: false,
      }));      

      console.log('Good response feedback sent');
    } catch (err) {
      console.error('Failed to send good response feedback: ', err);
    }
  };

  const handleReport = async () => {
    try {
      await handleFeedback('report', el.message_id);

      // Update local state to reflect feedback
      setFeedbackStatus(prev => ({
        ...prev,
        ["report"]: true,
      }));    
      setFeedbackStatus(prev => ({
        ...prev,
        ["good_response"]: false,
      }));      
      
      console.log('Report feedback sent');
    } catch (err) {
      console.error('Failed to send report feedback: ', err);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const renderers = {
    a: ({ node, ...props }) => (
      <a target="_blank" rel="noopener noreferrer" {...props} />
    ),
  };

  return (
    <>
      <Stack direction="column" padding={1} spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          <img
            alt={"Logo"}
            src={Logo}
            style={{
              height: 35,
              width: 35
            }}
          />
          <Typography variant="h6" sx={{ fontWeight: 550 }}>
            Answer
          </Typography>
        </Stack>
        <ReactMarkdown components={renderers}>
          {el.message}
        </ReactMarkdown>
      </Stack>
      <Box
        p={1}
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Tooltip title="Copy" arrow placement="top">
          <IconButton 
            size="small" 
            onClick={handleCopy}
            color={feedbackStatus.copy ? 'primary' : 'default'}
            sx={{
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: 'rgba(0, 114, 237, 0.04)'
              }
            }}
          >
            <Copy size={20} color={feedbackStatus.copy ? '#0072ED' : "#3A555A"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Good Response" arrow placement="top">
          <IconButton 
            size="small" 
            onClick={handleGoodResponse}
            color={feedbackStatus.good_response ? 'primary' : 'default'}
            sx={{
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: 'rgba(0, 114, 237, 0.04)'
              }
            }}
          >
            <ThumbsUp 
              size={20} 
              color={feedbackStatus.good_response ? '#0072ED' : "#3A555A"} 
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Report" arrow placement="top">
          <IconButton 
            size="small" 
            onClick={handleReport}
            color={feedbackStatus.report ? 'primary' : 'default'}
            sx={{
              transition: 'transform 0.2s ease-in-out',
              '&:hover': {
                transform: 'scale(1.1)',
                backgroundColor: 'rgba(0, 114, 237, 0.04)'
              }
            }}
          >
            <ThumbsDown 
              size={20} 
              color={feedbackStatus.report ? '#0072ED' : "#3A555A"} 
            />
          </IconButton>
        </Tooltip>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbarSeverity} 
          sx={{ 
            width: '100%',
            '& .MuiAlert-message': {
              fontSize: '0.9rem',
              fontWeight: 500
            },
            boxShadow: 3
          }}
          variant="filled"
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Divider sx={{ m: 1 }} />
    </>
  );
};

export { AnswerMsg, PromptMsg };