import React from 'react'
import { Stack, Box, Typography, CircularProgress } from '@mui/material'

import ChatInput from '../ChatInput'

const Footer = ({ onSendMessage, disable }) => {

    return (
        <Box
            sx={{
                height: 100,
                width: "100%",
                padding: 2,
            }}
        >
            <Stack direction="column" spacing={1}>

                <ChatInput onSendMessage={onSendMessage} disable={disable} />

                {!disable && (
                    <>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "grey",
                                textAlign: "center"
                            }}
                        >
                            IDA can make mistakes. Check important info.
                        </Typography>
                    </>
                )}
                {disable && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '24px' }}>
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        <Typography variant="body2" sx={{ color: "primary.main" }}>
                            IDA is processing your message...
                        </Typography>
                    </Box>
                )}
            </Stack>
        </Box>
    )
}

export default Footer