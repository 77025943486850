import React, { useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { styled } from "@mui/material/styles"
import { ArrowUp } from 'phosphor-react'

const StyledInput = styled(TextField)(() => ({
    '& .MuiInputBase-input': {
        padding: '16px 24px',
    },
    '& .MuiFilledInput-root': {
        borderRadius: '30px',
        overflow: 'hidden',
    },
}));

const ChatInput = ({ onSendMessage, disable }) => {

    const [message, setMessage] = useState('');

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (message.trim() && !disable) {
            onSendMessage(message);
            setMessage('');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey && !disable) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <StyledInput
            fullWidth
            value={message}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder='Message IDA'
            variant="filled"
            disabled={disable}
            InputProps={{
                disableUnderline: true,
                endAdornment:
                    <InputAdornment>
                        <IconButton
                            onClick={handleSendMessage}
                            disabled={disable}
                            sx={{
                                backgroundColor: disable ? "gray" : "black",
                                color: "white",
                                width: "38px",
                                height: "38px",
                                '&:hover': {
                                    backgroundColor: disable ? "gray" : "darkgray",
                                },
                            }}
                        >
                            <ArrowUp size={28} />
                        </IconButton>
                    </InputAdornment>
            }}
        />
    )
}

export default ChatInput