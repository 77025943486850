import React from "react";
import { Outlet } from "react-router-dom";
import { Stack } from "@mui/material";

const DashboardLayout = () => {

  return (
    <Stack direction="row">
      <Outlet />
    </Stack>
  );
};

export default DashboardLayout;
