import React from 'react';
import { Box, Typography, Tooltip } from "@mui/material";
import { WifiHigh, WifiSlash, ArrowsClockwise, Warning } from 'phosphor-react';

const WebSocketStatus = ({ status }) => {
  const getStatusIcon = () => {
    const iconStyle = { 
      color: getStatusColor(),
      width: '20px',
      height: '20px'
    };

    switch (status) {
      case 'connected':
        return <WifiHigh weight="fill" style={iconStyle} />;
      case 'connecting':
      case 'reconnecting':
        return <ArrowsClockwise weight="fill" style={iconStyle} />;
      case 'closed':
        return <WifiSlash weight="fill" style={iconStyle} />;
      case 'error':
        return <Warning weight="fill" style={iconStyle} />;
      default:
        return null;
    }
  };

  const getStatusColor = () => {
    switch (status) {
      case 'connected':
        return '#4caf50';
      case 'connecting':
      case 'reconnecting':
        return '#ffa000';
      case 'closed':
      case 'error':
        return '#f44336';
      default:
        return '#757575';
    }
  };

  const getStatusText = () => {
    switch (status) {
      case 'connected':
        return 'Connected';
      case 'connecting':
        return 'Connecting';
      case 'reconnecting':
        return 'Reconnecting';
      case 'closed':
        return 'Disconnected';
      case 'error':
        return 'Error';
      default:
        return 'Unknown';
    }
  };

  return (
    <Tooltip title={`IDA Status: ${getStatusText()}`}>
      <Box 
        display="flex" 
        alignItems="center" 
        sx={{
          padding: '4px 8px',
          borderRadius: '4px',
          backgroundColor: 'transparent',
          '& svg': {
            display: 'block',
          }
        }}
      >
        <Box display="flex" alignItems="center" mr={1}>
          {getStatusIcon()}
        </Box>
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: 500,
            color: getStatusColor(),
            fontSize: '0.875rem',
            lineHeight: 1,
          }}
        >
          {getStatusText()}
        </Typography>
      </Box>
    </Tooltip>
  );
};

export default WebSocketStatus;