import React from 'react'
import { Box } from "@mui/material";
import WebSocketStatus from '../WebSocketStatus';

const Header = ({ websocketStatus }) => {
    return (
        <Box
            p={0.5}
            sx={{
                height: 50,
                width: "100%",
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
            }}
        >
            <WebSocketStatus status={websocketStatus} />
        </Box>
    )
}

export default Header